import React, { useState, useEffect } from 'react';
import Patient from  '../filters/Patient';
import Days from '../filters/Days';
import './LabelFilters.css';


export default ({patients, days, patient, setPatient, setDays, type, setType}) => {

  return (
      <div className="chart-filters">
        <Patient patient={patient} setPatient={setPatient} patients={patients} />

        <form className="form-radio">
          <input type="checkbox" value="mealtype" id="user-label-box"
                 checked={type === 'mealtype'}
                 onChange={() => type === 'mealtype'? setType('fasting') : setType('mealtype')}
          />
          <label htmlFor="user-label-box">User Labels</label>
        </form>

      </div>
  )
}
