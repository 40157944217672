import React, { useState, useEffect } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import Glucose from './Glucose';
import LabelFilters from './LabelFilters';
import cubejs from '@cubejs-client/core';
import TimeSeries from './TimeSeries';
import Hours from './Hours';
import Density from './Densities';

export default ({patients, patient, setPatient, days, setDays, cubejsApi, minVal, maxVal, readings}) => {

  const [type, setType] = useState('fasting');
  const t = type === 'mealtype' ? 'Glucose.mealtype' : 'Glucose.fasting';
  const chartProps = {days, type: t, readings, minVal, maxVal};

  return (
    <div className="labels">
      <LabelFilters type={type} setType={setType} patients={patients} setPatient={setPatient} patient={patient} setDays={setDays} days={days}/>
      <div className="glucose-charts">
        <Hours {...chartProps} />
      </div>
    </div>
  );
}
