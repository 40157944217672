import React, { useState, useEffect } from 'react';
import history from './history';
import { Redirect, Route, Router } from 'react-router-dom';
import loading from './assets/circles.svg';
import _ from 'lodash';
import { defaults } from 'react-chartjs-2';
import './App.css';

import Charts from './charts';
import NavBar from './nav/NavBar';

import Auth from './auth/Auth.js';
import Home from './Home';
import Callback from './Callback';
import {FONT} from './branding';

_.merge(defaults, {
  global: {
    defaultFontFamily: FONT,
    defaultFontStyle: 'bold'
  },
});

// create a search box with autofill from the resultSet of pateints.externalid
// that should actually go into the state and let then rest of the rendering happen
// from there...x


const auth = new Auth();

const App = ({ }) => {


  const [token, setToken] = useState()

  const loggedIn = localStorage.getItem('isLoggedIn') === 'true'

  useEffect(() =>{
    if (loggedIn) {
      auth.renewSession(setToken);
    }
  }, [loggedIn])

  return (
    <div className="container">
      <NavBar auth={auth}/>
      <Router history={history}>
        <Route exact path="/" render={(props) => {
            if (auth.isAuthenticated()) {
              return <Redirect to={{pathname: "/charts/predictions"}} />
            }
            else if (loggedIn) {
              return <div className="callback"><img src={loading} alt="loading"/> </div>
            }
            return <Redirect to={{pathname: "/login"}} />
        }} />

      <Route path="/charts" render={(props) => {
        if (auth.isAuthenticated()) {
          const kannactId = getKannactIdFromParams(props.location.search)
          return <Charts {...props} token={token} defaultKannactId={kannactId}/>
        }
        else if (loggedIn) {
          return <div className="callback"><img src={loading} alt="loading"/> </div>
        }
        
        saveKannactIdFromParams(props.location.search)
        return <Redirect to={{pathname: "/login"}} />
      }} />
      <Route path="/login" render={(props) => <Home auth={auth} setToken={setToken}/>} />
      <Route exact path="/callback" render={(props) => {
        return <Callback auth={auth} setToken={setToken} {...props} />
              }}/>
      </Router>
    </div>
  )
}

/**
 * These two helpers below are used for being able to deep link into the charts pages 
 * using a specific kannactId.
 *  eg. 
 *    http://localhost:3000/charts/predictions?kannactId=IRC21734 
 *    http://localhost:3000/charts/labels?kannactId=IRC21734 
 * 
 * The localStorage logic is needed to handle the deep links when the user is not authenticated
 *  and needs to go through the login flow. To not lose the original intent of seeing the charts
 *  of a specific kannactId
 */

const getKannactIdFromParams = (searchParams) => {
  const params = new URLSearchParams(searchParams)
  
  const kannactId = params.get('kannactId') || localStorage.getItem('kannactId') || 'ARB02606'

  localStorage.removeItem('kannactId');

  return kannactId
}

const saveKannactIdFromParams = (searchParams) => {
  const params = new URLSearchParams(searchParams)
  const kannactId = params.get('kannactId')

  if (kannactId) {
    localStorage.setItem('kannactId', kannactId)
  }
}


export default App;
