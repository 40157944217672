import _ from 'lodash';
import { interpolateLab, interpolateCubehelix } from 'd3-interpolate';

const COLORS = ["#55A868", "#C44E52",
                "#937860", "#DA8BC3", "#8C8C8C", "#CCB974", "#64B5CD"];

export const colorizer = (labels) => {
  const col = COLORS.slice(0, labels.length);
  return _.zipObject(labels, col);
};

export const fastingColor = '#DD8452';
export const ppColor = "#4C72B0";

export const inter = interpolateCubehelix(ppColor, fastingColor);

export const boundsFasting = 'rgb(221, 132, 82, 0.3)';
export const boundsPP = 'rgb(76, 114, 176, 0.3)';
