import React from 'react';

export default ({auth }) => {
  const { isAuthenticated } = auth
  return (
      <div className="login">
      {
        !isAuthenticated() && (
          <button
            className="login-button"
            onClick={() => auth.login()}
            >
            Log In
          </button>
        )
      }
    {
      isAuthenticated() && (
        <button
          className="logout-button"
          onClick={() => auth.logout()}
          >
          Log Out
        </button>
      )
    }
    </div>
  )
}
