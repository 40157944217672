import React, { useState, useEffect } from 'react';

export default ({patient, setPatient, patients }) => {

  const [localPatient, setLocalPatient] = useState(patient)
  const handlePatient = (e) => setLocalPatient(e.target.value)

  const onSubmit = e => {
    e.preventDefault()
    setPatient(localPatient)
  }


  const downHandler = ({key}) => {
    let p
    const idx = patients.indexOf(patient)
    switch (key) {
    case 'ArrowLeft':
      p = patients[idx-1] || patients[patients.length - 1]
      setLocalPatient(p)
      setPatient(p)
      break
    case 'ArrowRight':
      p = patients[idx+1] || patients[0]
      setLocalPatient(p)
      setPatient(p)
      break
    default:
      return
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [patients, patient]);

  return (
    <div className="filter-patient">
         <form className="form-patient" onSubmit={onSubmit}>
           <input list="patients" value={localPatient} onChange={handlePatient}/>
           <datalist id="patients">
      { patients.map(p => <option key={p} value={p} />)}
    </datalist>
      </form>

    </div>
  )
}
