import React from 'react';
import {reliabilityIcon, trendIcon} from './vitals-icons/Icons';
import {ppColor, fastingColor} from '../utils';

export default (props) => {
  const fastingTrend = getTrendFromVitals(props.vitals, 'f');
  const ppTrend = getTrendFromVitals(props.vitals, 'p');
  const reliabilityBase = 10;
  const fastingReliability = getReliabilityFromVitals(props.vitals, 'f', reliabilityBase);
  const ppReliability = getReliabilityFromVitals(props.vitals, 'p', reliabilityBase);

  const getTrendIcon = ([lower, upper], color) => {
    if (lower > 0. && upper > 0.) return trendIcon('up', color)
    else if (lower < 0. && upper < 0.) return trendIcon('down', color)
    return trendIcon('center', color)
  }

  const formatPercentage = (nums) => {
    const rounded = nums.map(n => (n*100).toFixed(1))
    const values = rounded.map(r => r > 0. ? '+' + r + '%' : r + '%');

    return (
      <span>
        <span>{values[0]}</span>
        <span>&ndash;</span>
        <span>{values[1]}</span>
      </span>
    )
  };

  const noDataPlaceholder = () => (
    <span>
      No data
    </span>
  )


  const formatFraction = (base, num) => {
    return (<span style={{fontSize: '.7em', lineHeight: '1em'}}><sup style={{fontSize:'3em'}}>{num}</sup>/<sub>{base}</sub></span>)
  };

  const vitals = [
    { type: 'trend', text: 'Fasting Trend', value: fastingTrend !== null ? formatPercentage(fastingTrend): noDataPlaceholder(), icon: fastingTrend ? getTrendIcon(fastingTrend, fastingColor) : undefined, color: fastingColor },
    { type: 'trend', text: 'Postprandial Trend', value: ppTrend !== null ? formatPercentage(ppTrend): noDataPlaceholder(), icon: fastingTrend ? getTrendIcon(ppTrend, ppColor) : undefined, color: ppColor },
    { type: 'reliability', text: 'Fasting Reliability', value: fastingReliability !== null ? formatFraction(reliabilityBase, fastingReliability) : noDataPlaceholder(), icon: fastingReliability ? reliabilityIcon(fastingColor) : undefined, color: fastingColor},
    { type: 'reliability', text: 'Postprandial Reliability', value: ppReliability !== null ? formatFraction(reliabilityBase, ppReliability) : noDataPlaceholder(), icon: ppReliability ? reliabilityIcon(ppColor) : undefined, color: ppColor}
  ]


  const vitalsComponents = vitals.map(({type, text, value, icon, color}) => {

    const styles = {width: '200px'};

    return (
      <div className="vitals-component" style={styles}>
        <h3 className="title">{text}</h3>
        <div className="vital">
          { icon ? <span className="icon" style={ type === 'trend' ? {width: '40%'} : {width: '60%'}}>{icon}</span> : null }
          <span className="value" style={{color: color}}> {value}</span>
        </div>
      </div>
    )
  })


  return (
    <div className="vitals">
      {vitalsComponents}
    </div>
  )
}

const getTrendFromVitals = (vitals, type) => {
  const lowerVital = vitals.find(v => v['Vitals.keys'] === `${type}_slope_lower`);
  const upperVital = vitals.find(v => v['Vitals.keys'] === `${type}_slope_upper`);

  if (!lowerVital || !upperVital) return null
  
  return [lowerVital['Vitals.values'], upperVital['Vitals.values']]
}

const getReliabilityFromVitals = (vitals, type, base) => {
  const reliabilityVital = vitals.find(v => v['Vitals.keys'] === `${type}_reliability`);

  if (!reliabilityVital) return null

  const reliabilityValue = Math.round(Math.max(1 - reliabilityVital['Vitals.values'], 0) * base);

  return reliabilityValue
}