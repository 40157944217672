 import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Scatter } from 'react-chartjs-2';
import { colorizer,inter, boundsFasting, boundsPP } from '../utils';
import { interpolateLab, interpolateCubehelix } from 'd3-interpolate';
import {timeseries, byFasting, fastingLegend} from '../datasets';


export default ({ activePreds, days, readings, predictions, minVal, maxVal }) => {

  const timeKey = 'Glucose.usertime';

  const d = readings.map(d => ({x: moment(d['Glucose.usertime']), y: +d['Glucose.unitvalue']}));

  const glucoseReadings = byFasting(d, readings, days);

  const fastingBounds = [
    {
      label: 'f_lower',
      type: 'line',
      showLine: true,
      pointRadius: 0,
      fill: false,
      borderColor: boundsFasting,
      data: predictions.map(d => ({x: new Date(d['Predictions.timestamp']), y: +d['Predictions.f_lower']}))
    },
    {
      label: 'f_upper',
      type: 'line',
      showLine: true,
      pointRadius: 0,
      backgroundColor: boundsFasting,
      borderColor: boundsFasting,
      fill: '-1',
      data: predictions.map(d => ({x: new Date(d['Predictions.timestamp']), y: +d['Predictions.f_upper']}))
    }
  ];

  const pPBounds = [
    {
      label: 'p_lower',
      type: 'line',
      showLine: true,
      pointRadius: 0,
      fill: false,
      borderColor: boundsPP,
      data: predictions.map(d => ({x: new Date(d['Predictions.timestamp']), y: +d['Predictions.p_lower']}))
    },
    {
      label: 'p_upper',
      type: 'line',
      showLine: true,
      pointRadius: 0,
      backgroundColor: boundsPP,
      borderColor: boundsPP,
      fill: '-1',
      data: predictions.map(d => ({x: new Date(d['Predictions.timestamp']), y: +d['Predictions.p_upper']}))
    }
  ];

  const lookup = {
    'fasting': fastingBounds,
    'postprandial': pPBounds,
  };
  const extras = _(activePreds).map(p => lookup[p]).flatten().value();
  const data = {
    datasets: [...glucoseReadings, ...extras]
  };


  const options = {
    legend: fastingLegend(activePreds),
    // legend: false,
    scales: {
      yAxes: [{
        ticks: {
          min: minVal,
          max: maxVal
        }
      }],
      xAxes: [{
        type: 'time',
        time: {
          unit: 'day',
          unitStepSize: 1,
          displayFormats: {
            day: 'MMM Do'
          }
        },
      }]
    }
  };

  return (

    <div className="chart-timeseries">
      <Scatter width={900} height={320} data={data} options={options}/>
    </div>
  );
}
