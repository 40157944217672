import React from 'react';
import {LOGO}from '../branding';
import logout from '../assets/logout.svg';
import history from '../history';
import './NavBar.css';

export default ({auth}) => {

  return (
    <div className="navbar">
      <button onClick={() => history.replace('/')}>
      <img className="logo" src={LOGO} />
      </button>
      <button className="logout" onClick={() => auth.logout()}>
      <img  src={logout} />
        </button>
    </div>
  )
}
