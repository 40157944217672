import React, { useState, useEffect } from 'react';
import history from '../history';
import _ from 'lodash';
import { Redirect, Route, Router, Link } from 'react-router-dom';
import { QueryRenderer } from '@cubejs-client/react';
import Labels from './labels';
import Predictions from './predictions';
import moment from 'moment';
import cubejs from '@cubejs-client/core';
import './Charts.css';
import { Chart } from 'react-chartjs-2';
import {verticalLinePlugin} from './plugins';

import wand from '../assets/magic-wand.svg';
import clock from '../assets/clock.svg';


const roundToNearest = (target, num, above) => {
  const frac = num / target;
  const mult = above ? Math.ceil(frac) : Math.floor(frac);
  return mult*target;
}

export default ({match, token, location, defaultKannactId}) => {

  const cubejsApi = cubejs(localStorage.getItem("analyticsToken"), {
    apiUrl: `${process.env.REACT_APP_CUBE_API_URL}/cubejs-api/v1`,
  });

  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState(defaultKannactId);
  // TODO: Remove all the days logic from everywhere
  const [days, setDays] = useState(90);
  const [readings, setReadings] = useState([]);
  const [activePreds, setActivePreds] = useState(['fasting', 'postprandial']);

  useEffect(() => {
    Chart.pluginService.register(verticalLinePlugin);
  }, []);

  useEffect(() => {
    cubejsApi.load({
      dimensions: ['Glucose.patient' ],
      filters: [
        {
          dimension: 'Glucose.count',
          operator: 'gt',
          values: ['100']
        },
      ]
    }).then(resultSet => {
      setPatients(resultSet.rawData().map(o => o['Glucose.patient']));
    });
  }, []);

  const makeFilters = (table) => {
    return [
      {
        dimension: `${table}.patient`,
        operator: 'equals',
        values: [patient]
      },
      {
        dimension: `${table}.timestamp`,
        operator: 'afterDate',
        values: [moment('2015-04-01').subtract(+days, 'days').format('YYYY-MM-DD')]
      },
      {
        dimension: `${table}.timestamp`,
        operator: 'beforeDate',
        values: [moment().format('YYYY-MM-DD')]
      }
    ]
  };

  return (
    <QueryRenderer
      queries={{
        readings: {
          dimensions: ['Glucose.usertime', 'Glucose.timestamp', 'Glucose.unitvalue', 'Glucose.mealtype', 'Glucose.fasting'],
          filters: makeFilters('Glucose')
        },
        predictions: {
          dimensions: ['Predictions.timestamp', 'Predictions.p_lower', 'Predictions.p_upper', 'Predictions.f_lower', 'Predictions.f_upper', 'Predictions.swing_lower', 'Predictions.swing_upper'],
          filters: makeFilters('Predictions')
        },
        vitals: {
          dimensions: ["Vitals.keys", "Vitals.values"],
          filters: [
            {
              dimension: 'Vitals.patient',
              operator: 'equals',
              values: [patient]
            }
          ]
        }
      }}
      cubejsApi={cubejsApi}
      render={({ resultSet }) => {

        if (_.isEmpty(resultSet)) {
          return null;
        }

        const predictions = resultSet.predictions.rawData();
        const readings = resultSet.readings.rawData();
        const vitals = resultSet.vitals.rawData();

        const vals = readings.map(({'Glucose.unitvalue': val}) => val);

        const minVal = roundToNearest(50, vals.reduce((r,v) => Math.min(r,v), 50), false);
        const maxVal = roundToNearest(50, vals.reduce((r,v) => Math.max(r,v), 100), true);

        const chartProps = { activePreds,
                             setActivePreds,
                             cubejsApi,
                             readings,
                             predictions,
                             vitals,
                             patients,
                             patient,
                             setPatient,
                             days,
                             setDays,
                             minVal,
                             maxVal };

        const navs = [
          [`${match.url}/predictions`, wand],
          [`${match.url}/labels`, clock],
        ].map(([path, label], i) => <button
                                      key={i}
                                      className={location.pathname == path ? 'active': null}
                                      onClick={() => history.replace(path)}>
                                      <img className="mininav" src={label} />
                                    </button> );

        return (
          <div className="charts">
            <Router history={history}>
              <div className="chart-container">
                <Route path={`${match.path}/labels`} render={(props) => <Labels {...chartProps} /> } />
                <Route path={`${match.path}/predictions`} render={(props) => <Predictions {...chartProps} /> } />
              </div>
            </Router>
            <div className="chart-picker">
              {navs}
            </div>
          </div>
        );
      }}
    />
  );
}
