import React, { useEffect } from 'react';
import loading from './assets/circles.svg';
import history from './history';

export default (props) => {

  const handleAuthentication = ({location}) => {
    if (/access_token|id_token|error/.test(location.hash)) {
      props.auth.handleAuthentication(props.setToken);
    } else {
      history.replace('/');
    }
  }

  useEffect(() => {
    handleAuthentication(props);
  }, [])

  return (
      <div className="callback">
      <img src={loading} alt="loading"/>
      </div>
  );
}
