export const verticalLinePlugin = {
  getLinePosition: function (chart, pointIndex) {
    const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
    const data = meta.data;
    return data[pointIndex]._model.x;
  },
  renderVerticalLine: function (chartInstance, pointValue, text) {

    const scale = chartInstance.scales['y-axis-1'];
    const xscale = chartInstance.scales['x-axis-1'];

    const lineLeftOffset = xscale.getPixelForValue(pointValue);

    const context = chartInstance.chart.ctx;

    // render vertical line
    context.beginPath();
    context.strokeStyle = '#000000';
    context.moveTo(lineLeftOffset, scale.top);
    context.lineTo(lineLeftOffset, scale.bottom);
    context.stroke();

    // write label
    context.fillStyle = "#000000";
    context.textAlign = 'left';
    context.fillText(` ${text}`, lineLeftOffset, scale.top + 10);
  },

  afterDatasetsDraw: function (chart, easing) {
    if (chart.options.lineAtValue) {
      chart.options.lineAtValue.forEach(({value, text}) => this.renderVerticalLine(chart, value, text));
    }
  }
};
