import { interpolateLab, interpolateCubehelix } from 'd3-interpolate';
import _ from 'lodash';
import { colorizer,inter, boundsFasting, boundsPP } from './utils';
import cjs from 'chart.js';
const helpers = cjs.helpers;

export const defaultLabels = (chart) => {
  var data = chart.data;
  return helpers.isArray(data.datasets) ? data.datasets.map(function(dataset, i) {
    return {
      text: dataset.label,
      fillStyle: (!helpers.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
      hidden: !chart.isDatasetVisible(i),
      lineCap: dataset.borderCapStyle,
      lineDash: dataset.borderDash,
      lineDashOffset: dataset.borderDashOffset,
      lineJoin: dataset.borderJoinStyle,
      lineWidth: dataset.borderWidth,
      strokeStyle: dataset.borderColor,
      pointStyle: dataset.pointStyle,

      // Below is extra data used for toggling the datasets
      datasetIndex: i
    };
  }, this) : [];
};

export const byFasting = (d, readings, days) => {

  const glucoseReadings = { label: 'readings',
                            type: 'scatter',
                            backgroundColor: readings.map(r => r['Glucose.fasting']).map(v => inter(v)),
                            borderColor: readings.map(r => r['Glucose.fasting']).map(v => inter(v)),
                            // backgroundColor: 'black',
                            pointRadius: Math.max(2, Math.ceil((1 / Math.sqrt(Math.ceil(days/8))) * 9)),
                            pointStyle: 'circle',
                            data: d };

  return [glucoseReadings];
};



export const fastingLegend = (ps=[]) => {

  const lookup = {
    fasting: boundsFasting,
    postprandial: boundsPP
  };

  return {
    display: true,
    labels: {
      generateLabels(t) {
        const a = [ { text: 'FASTING', fillStyle: inter(1.)},
                    { text: 'UNCERTAIN', fillStyle: inter(0.5)},
                    { text: 'PP', fillStyle: inter(0.)}];


        const extras = ps.map(p => ({ text: `${p} trendline (95% interval)`.toUpperCase(), fillStyle: lookup[p] }));
        return [...a, ...extras];
      }
    }
  };
};

export const byType = (readings, type, days, transformer, colors = colorizer([ 'UNSPECIFIED', 'POST',  'PRE', 'CONTROL', 'EAG'])) => {
  const d = _(readings)
    .groupBy(d => d[type])
    .map((v,k) => ({ label: k.toUpperCase(), data: transformer(v) }))
    .value();

  return d.map(o => ({...o,
                      type: 'scatter',
                      backgroundColor: colors[o.label],
                      borderColor: colors[o.label],
                      pointRadius: Math.max(2, Math.ceil((1 / Math.sqrt(Math.ceil(days/8))) * 9))}));
};
