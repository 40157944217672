import React, { useState, useEffect } from 'react';
import Patient from  '../filters/Patient';
import Days from '../filters/Days';

export default ({patients, days, patient, setPatient, setDays, activePreds, setActivePreds }) => {

  const checks = ['fasting', 'postprandial'];

  const checkForms = checks.map(input => {
    return (
      <span key={input} className="pred-checkbox">
        <input type="checkbox"
               id={`${input}-prediction-box`}
               value={input}
               checked= { activePreds.find(x => x === input)}
               onChange={() => activePreds.find(x => x === input) ?
                         setActivePreds(activePreds.filter(x => x !== input)) :
                         setActivePreds([...activePreds, input])}
        />
        <label htmlFor={`${input}-prediction-box`}> {`${input} Trendline`} </label>
      </span>
    );
  });

  return (
      <div className="chart-filters">
        <Patient patient={patient} setPatient={setPatient} patients={patients} />
        <form className="form-radio">
          { checkForms }
          <span className="trend-note"> NOTE: Intervals represent uncertainty around the trendline.  </span>
        </form>
      </div>
  );
}
