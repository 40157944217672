import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {Line, Scatter} from 'react-chartjs-2';
import {colorizer, inter} from '../utils';
import { byType, byFasting, fastingLegend, defaultLabels } from '../datasets';

export default ({ type, days, readings, minVal, maxVal }) => {
  let glucoseReadings;
  let legend;

  const getHour = (d) => moment(new Date(d)).format('HH:mm');

  const transformer = v => v.map(d => ({x: getHour(d['Glucose.usertime']), y: +d['Glucose.unitvalue']}));
  if (type === 'Glucose.fasting') {
    glucoseReadings = byFasting(transformer(readings), readings, days);
    legend = fastingLegend();
  }
  else {
    glucoseReadings = byType(readings, type, days, transformer).map(o => ({...o, pointStyle: 'circle'}));
    legend = undefined;
  }

  const data = { datasets: glucoseReadings };

  const baseOptions = {
    animation: false,
    scales: {
      yAxes: [{
        ticks: {
          min: minVal,
          max: maxVal
        }
      }],
      xAxes: [{
        type: 'time',
        time: {
          parser: 'HH:mm',
          unit: 'hour',
          min: '00:00',
          max: '23:59',
          unitStepSize: 1,
          displayFormats: {
            hour: 'HH:mm',
            min: '00:00',
            max: '23:59'
          }
        },
      },
     ]
    }
  };

  const options = legend ? {...baseOptions, legend } : {...baseOptions, legend: {display: true, labels: { generateLabels: defaultLabels }}};

  return (
    <div className="chart-hours">
      <Scatter width={500} height={200} data={data} options={options}/>
    </div>
  );
}
