import React, { useState, useEffect } from 'react';
import { Redirect, Route, Router } from 'react-router-dom';
import Glucose from '../labels/Glucose';
import PredictionFilters from './PredictionFilters';
import TimeSeries from './TimeSeries';
import Swings from './Swings';
import Vitals from './Vitals';
import './Predictions.css';
import cubejs from '@cubejs-client/core';

export default ({ activePreds, setActivePreds, patients, patient, predictions, vitals, setPatient, days, setDays, cubejsApi, minVal, maxVal, readings}) => {


  const chartProps = {days, readings, predictions, vitals, minVal, maxVal, activePreds};

  return (
    <div className="predictions">
      <PredictionFilters activePreds={activePreds} setActivePreds={setActivePreds} patients={patients} setPatient={setPatient} patient={patient} setDays={setDays} days={days}/>
      <div className="prediction-charts">

        <TimeSeries {...chartProps} />
        <Vitals {...chartProps} />
        {/* <Swings {...chartProps} /> */}
      </div>
    </div>
  );
}
